import React, { useState } from "react";
import emailjs from "emailjs-com";
const Contacto = () => {
  const frmContact = {
    userEmail: "",
    concernCategory: "",
    emailTitle: "",
    emailDetails: "",
  };
  const [contact, setContact] = useState(frmContact);
  const [showMessage, setShowMessage] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "default_service",
        "template_ti7en8u",
        contact,
        "user_5Ii3T1GcW1R4NM4q2SD7j"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setContact(frmContact);
          setShowMessage(true);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };
  return (
    <>
      <div className="container margin_navBar">
        <div className="row">
          <div className="col-12">
            <h1 className="titulos-page mt-4">CONTACTANOS</h1>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-10 offset-1 fondo-parrafo1">
            <div className="content-parrafo1">
              <div className="container pt-2 text-center">
                {showMessage ? (
                  <div
                    className="alert alert-success col-md-5 mx-auto"
                    role="alert"
                  >
                    Email Send Success!!
                  </div>
                ) : (
                  ``
                )}

                <form onSubmit={handleSubmit}>
                  <div className="pt-3 col-md-5 mx-auto">
                    <div className="form-group text-left">
                      {" "}
                      <b>Email:</b> <br />
                      <input
                        required
                        type="text"
                        value={contact.userEmail}
                        name="userEmail"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="pt-3 col-md-5 mx-auto">
                    <div className="form-group text-left">
                      {" "}
                      <b>Asunto:</b> <br />
                      <select
                        required
                        className="form-control"
                        value={contact.concernCategory}
                        onChange={handleChange}
                        name="concernCategory"
                      >
                        <option value="">Select</option>
                        <option value="info">Información</option>
                        <option value="buy">Compra</option>
                        <option value="service">Servicio</option>
                        <option value="other">Otros</option>
                      </select>
                    </div>
                  </div>
                  <div className="pt-3 col-md-5 mx-auto">
                    <div className="form-group text-left">
                      {" "}
                      <b>Titulo:</b> <br />
                      <input
                        value={contact.emailTitle}
                        required
                        type="text"
                        name="emailTitle"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Titulo"
                      />
                    </div>
                  </div>
                  <div className="pt-3 col-md-5 mx-auto">
                    <div className="form-group text-left">
                      {" "}
                      <b>Comentarios:</b> <br />
                      <textarea
                        required
                        name="emailDetails"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Comentarios..."
                        value={contact.emailDetails}
                      ></textarea>
                    </div>
                  </div>
                  <div className="pt-3 col-md-5 mx-auto text-right">
                    <button className="btn btn-primary">ENVIAR</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contacto;





