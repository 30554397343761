import React, { useState, useRef, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
const ModalPrincipal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/*<Modal.Header className="modal-headerWP" closeButton>
        <Modal.Title className="titulos-modal mt-2">Promoción</Modal.Title>
      </Modal.Header>*/}
      <Modal.Body>
        <div className="row cuerpo-modal border-promos-modal">
          <div className="col-lg-12">
          {/* <img
                  className="d-block w-100 imgCarrouselP"
                  src="./assets/img/promos/promo_jueves20_2.png"
                  alt="First slide"
                /> */}
            <Carousel className="fodoCarousel2">
              <Carousel.Item>
                <img
                  className="d-block w-100 imgCarrouselP"
                  src="./assets/img/promos/promo_jueves20_2.png"
                  alt="First slide"
                />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src="./assets/img/promos/promo_noche.png" alt="First slide" />
                <Carousel.Caption>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src="./assets/img/promos/promo_proximamente.png" alt="First slide" />
                <Carousel.Caption>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
        {/* <div className="row cuerpo-modal border-promos-modal">
          <div className="col-lg-6">
            <img
              className="w-100 mt-4"
              src="./assets/img/promos/PMartes.png"
              alt="new wings place"
            />
          </div>
          <div className="col-lg-6">
            <ul className="mt-2 titulos-modal-content">
            <li>Valido todos los Martes de la apertura hasta las 9:00pm.</li><li>Promoción personal, no aplica para llevar, ni con otras promociones.</li><li>La primera orden se sirve de 10 piezas de alitas o boneless, incluye 1 aderezo, 2 salsas y vegetales.</li><li>Para ordenar la segunda orden , se debera haber terminado la primera orden.</li><li>A partir de la segunda orden se serviran ordenes de 5 piezas de alitas o boneless(no combinados) y 1 salsa a elegir.</li><li>En caso de ordenar aderezo, salsa o vegetales extras, estos se cobraran a precio normal.</li><li>Precio sujeto a cambio sin previo aviso.</li>
            </ul>
          </div>
        </div>
        <div className="row cuerpo-modal border-promos-modal mt-2">
          <div className="col-lg-6">
            <img
              className="w-100 mt-4"
              src="./assets/img/promos/PJueves.png"
              alt="new wings place"
            />
          </div>
          <div className="col-lg-6">
            <ul className="mt-2 titulos-modal-content">
            <li>Aplica todos los Jueves de la apertura hasta las 10:30pm o hasta agotar existencias.</li><li>No aplica con otras promociones.</li><li>Incluye 20 piezas de alitas o boneles, 1 aderezo, 2 salsas y vegetales.</li><li>En envios a domicilio se cobrará cargo por envio  $25.</li><li>Precio sujeto a cambio sin previo aviso.</li>
            </ul>
          </div>
        </div> */}
        </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPrincipal;
