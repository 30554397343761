import React from "react";
import "../assets/css/polaroid.css";

const Historia = () => {
  return (
    <>
      <div className="container margin_navBar">
        <div className="row">
          <div className="col-12">
            <h1 className="titulos-page mt-4">HISTORIA</h1>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-10 offset-1 fondo-parrafo1">
            <div className="content-parrafo1">
              <p className="parrafo-info">
                <span className="enfasisWP">Wings Place</span> abre puertas el
                16 de octubre de 2016, operando un local de 25 m2 y tenía como
                único menú: alitas, cervezas y refresco.<br/>
                <br/>
                La primera ampliación
                que sufrió fue en junio 2017 haciendo modificaciones para la
                expansión de la cocina, posteriormente para el año de 2019
                extiende la operación con un segundo local.<br/>
                <br/>
                Actualmente se tienen
                operando dos sucursales en Ciudad de México, una ubicada en San Andrés Tomatlán (Iztapalapa) y
                otra en Avenida Guillermo Prieto (Tlahuac).<br/>
                <br/>
                Estamos en
                constante cambio y crecimiento, buscamos la implementación de
                estrategias para convertirnos en una marca institucional.
              </p>
              <div className="wrapper">
                <div className="item">
                  <div className="polaroid">
                    <img
                      alt="OldWP"
                      src="./assets/img/gallery/historia1.png"
                    />
                    <div className="caption">I &hearts; <span className="enfasisWP-polaroid">WingsPlace</span></div>
                  </div>
                </div>
                <div className="item">
                  <div className="polaroid">
                    <img
                      alt="OldWP"
                      src="./assets/img/gallery/historia2.png"
                    />
                    <div className="caption">I &hearts; <span className="enfasisWP-polaroid">WingsPlace</span></div>
                  </div>
                </div>
                <div className="item">
                  <div className="polaroid">
                    <img
                      alt="OldWP"
                      src="./assets/img/gallery/historia3.png"
                    />
                    <div className="caption">I &hearts; <span className="enfasisWP-polaroid">WingsPlace</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};
export default Historia;
