/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import logo from "../assets/img/Logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faBalanceScale,
  faLock,
  faBook,
  faAt,
  faFilm,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const FooterWP = () => {
  const topScrollWP = () => {
    var toTopInterval = setInterval(function(){
  
      var supportedScrollTop = document.body.scrollTop > 0 ? document.body : document.documentElement;
  
      if (supportedScrollTop.scrollTop > 0) {
          supportedScrollTop.scrollTop = supportedScrollTop.scrollTop - 50;
      }
  
      if (supportedScrollTop.scrollTop < 1) {
          clearInterval(toTopInterval);
      }
  
  }, 10);
  }
  return (
    <footer className="row-fluid footerHome">
      <div className="col-12 text-center">
        <div className="row text-center">
          <object
            className="img-fluid logo-footer"
            type="image/svg+xml"
            data={logo}
          />
        </div>
        <div className="row">
          <div className="col-12 col-md-4 text-center">
            <ul className="listaSociales-footer">
              <li>
                <a
                  href="https://www.facebook.com/wingsplace.mexico/"
                  rel="noreferrer"
                  target="_blank"
                  className="txtFooterRS"
                >
                  <FontAwesomeIcon icon={faFacebook} className="iconListText" />{" "}
                  wingsplace.mexico
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/wingsplacemx/?hl=es-la"
                  rel="noreferrer"
                  target="_blank"
                  className="txtFooterRS"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="iconListText"
                  />{" "}
                  @wingsplacemx
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-4 text-left">
            <ul className="listaText-footer">
              <li className="txtFooter" onClick={topScrollWP}>
                <Link to="/historia">
                  <FontAwesomeIcon icon={faFilm} className="iconListText" />{" "}
                  HISTORIA
                </Link>
              </li>
              <li className="txtFooter" onClick={topScrollWP}>
                <Link to="/quienes_somos">
                  <FontAwesomeIcon icon={faUsers} className="iconListText" />{" "}
                  QUIENES SOMOS
                </Link>
              </li>
              <li className="txtFooter" onClick={topScrollWP}>
                <Link to="/quienes_somos">
                  <FontAwesomeIcon
                    icon={faBalanceScale}
                    className="iconListText"
                  />{" "}
                  EMPLEO
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-4 text-left">
            <ul className="listaText-footer">
              <li className="txtFooter" onClick={topScrollWP}>
                <Link to="/quienes_somos">
                  <FontAwesomeIcon icon={faBook} className="iconListText" />{" "}
                  TERMINOS Y CONDICIONES
                </Link>
              </li>
              <li className="txtFooter" onClick={topScrollWP}>
                <Link to="/aviso_privacidad">
                  <FontAwesomeIcon icon={faLock} className="iconListText" />{" "}
                  AVISO DE PRIVACIDAD
                </Link>
              </li>
              <li className="txtFooter" onClick={topScrollWP}>
                <Link to="/contacto">
                  <FontAwesomeIcon icon={faAt} className="iconListText" />{" "}
                  CONTÁCTO
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row-fluid text-center">
          <hr className="hrFooter" />
          <h5 className="titleFooter">WINGS PLACE MÉXICO &#xae;</h5>
          <span className="subTitleFooter">
            <a
              href="https://www.xdevelopers.net"
              rel="noreferrer"
              target="_blank"
            >
              DEVELOPED BY X-DEVELOPERS
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};
export default FooterWP;
