import React from "react";
import "../assets/css/polaroid.css";

const AvisoPrivacidad = () => {
  return (
    <>
      <div className="container margin_navBar">
        <div className="row">
          <div className="col-12">
            <h3 className="titulos-page mt-4">AVISO DE PRIVACIDAD</h3>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-10 offset-1 fondo-parrafo1">
            <div className="content-parrafo1">
              <p className="parrafo-info">
                <h4 className="text-center">
                  AVISO DE PRIVACIDAD PARA CLIENTES DE FRASERGA FRANQUICIAS Y
                  SERVICIOS GASTRONÓMICOS, S. DE R.L. DE C.V.
                </h4>
                <span className="enfasisWP3 mt-2">
                  1.- “Responsable” del tratamiento de sus datos personales:
                </span>
                <p className="text-justify mt-2">
                  <strong>
                    Fraserga Franquicias y Servicios Gastronómicos, S. de R.L.
                    de C.V.
                  </strong>
                  , es la persona moral, que de acuerdo con la Ley Federal de
                  Protección de Datos Personales en Posesión de los Particulares
                  (LFPDPPP) se entiende como el <strong>“Responsable”</strong>{" "}
                  ya que, decide sobre el tratamiento de los datos personales
                  que racaba de usted, como <strong>“Titular”</strong> de los
                  mismos, entendiéndose como <strong>“Titular”</strong> la
                  persona física a quien corresponden los datos personales, y
                  por éstos últimos, cualquier información concerniente a una
                  persona física identificada o identificable.
                </p>
                <p className="text-justify mt-2">
                  Para el <strong>“Responsable”</strong>, el tratar sus datos de
                  manera legítima y en apego a la Ley Federal de la materia,
                  resulta un tema prioritario. Este Aviso de Privacidad
                  complementa cualesquiera otros avisos de privacidad
                  simplificados o cortos que el <strong>“Responsable”</strong>{" "}
                  haya puesto a su disposición por ser el titular de sus datos
                  personales y resulta supletorio en todo aquello que
                  expresamente no refieran tales avisos.
                </p>
                <span className="enfasisWP3 mt-2">
                  2.- Domicilio del <strong>“Responsable”</strong>:
                </span>
                <p className="text-justify mt-2">
                  Callejón Independencia Número 7, Col. San Andrés Tomatlán,
                  Alcaldía Iztapalapa, C.P. 09870, Ciudad de México. Dicho
                  domicilio es el establecido por el Responsable para oír y
                  recibir notificaciones.
                </p>
                <span className="enfasisWP3 mt-2">
                  3.- Finalidades del Tratamiento de los datos personales de los
                  CLIENTES del <strong>“Responsable”</strong>:
                </span>
                <p className="text-justify mt-2">
                  El <strong>“Responsable”</strong> en este acto recaba sus
                  datos para las siguientes finalidades:
                  <ol type="A">
                    <li>
                      Prestarle los servicios profesionales que usted requiera,
                      de conformidad con la propuesta que de los mismos el{" "}
                      <strong>“Responsable”</strong> le presente. Los servicios
                      profesionales que el <strong>“Responsable”</strong> puede
                      prestarle son en el ramo de franquicias y servicios
                      gastronómicos
                    </li>
                    <li>
                      Dar cumplimiento a las obligaciones contraídas con usted
                      de acuerdo a los servicios profesionales que se le
                      presten, así como, informarle cualquier situación o cambio
                      sobre dichos servicios
                    </li>
                    <li>
                      Evaluar la calidad de los servicios profesionales que
                      presta el <strong>“Responsable”</strong> y llevar a cabo
                      encuestas de satisfacción de clientes
                    </li>
                    <li>
                      Mantener contacto con usted con objeto de invitarlo a
                      promociones, ofertas, eventos u otros que se relacionen
                      con los servicios profesionales que el{" "}
                      <strong>“Responsable”</strong> le presta
                    </li>
                  </ol>
                </p>
                <span className="enfasisWP3 mt-2">
                  4.- Datos personales que recaba el{" "}
                  <strong>“Responsable”</strong> de sus clientes:
                </span>
                <p className="text-justify mt-2">
                  Para llevar a cabo las finalidades descritas en el presente
                  Aviso de Privacidad el <strong>“Responsable”</strong>
                  utilizará datos personales de identificación, contacto y para
                  facturación.
                </p>
                <span className="enfasisWP3 mt-2">
                  5.- Datos personales sensibles:
                </span>
                <p className="text-justify mt-2">
                  El <strong>“Responsable”</strong> le informa que para cumplir
                  con las finalidades previstas en este Aviso de Privacidad, NO
                  requiere recabar de usted datos personales sensibles.
                </p>
                <span className="enfasisWP3 mt-2">
                  6.- Uso de cookies y web beacons:
                </span>
                <br />
                <span className="enfasisWP3 mt-2">
                  7.- Opciones y medios para limitar el uso o divulgación de sus
                  datos personales:
                </span>
                <p className="text-justify mt-2">
                  Para limitar el uso o divulgación de sus datos personales, a
                  fin de que no sean tratados para fines mercadotécnicos o
                  publicitarios por parte del <strong>“Responsable”</strong>{" "}
                  usted puede contactar directamente al Departamento de Datos
                  Personales del <strong>“Responsable”</strong>.
                </p>
                <span className="enfasisWP3 mt-2">
                  8.- Medios para ejercer los Derechos de Acceso, Rectificación,
                  Cancelación y Oposición (Derechos ARCO):
                </span>
                <p className="text-justify mt-2">
                  Usted tiene el derecho de acceder a sus datos personales que
                  posea el <strong>“Responsable”</strong> y a los detalles del
                  tratamiento de los mismos, así como a rectificarlos en caso de
                  ser inexactos o incompletos; cancelarlos cuando considere que
                  no se requieren para alguna de las finalidades señaladas en el
                  presente aviso de privacidad, estén siendo utilizados para
                  finalidades no consentidas o bien oponerse al tratamiento de
                  los mismos para fines específicos. El Departamento de Datos
                  Personales del
                  <strong>“Responsable”</strong> le brindará toda la información
                  necesaria para que usted proceda al ejercicio de sus Derechos
                  de Acceso, Rectificación, Cancelación y Oposición respecto de
                  sus datos personales. El procedimiento que se ha implementado
                  para el ejercicio de dichos derechos, inicia con la
                  presentación de la solicitud respectiva, cuyo formato e
                  información respecto de los plazos en los que será atendida,
                  los podrá obtener en el Departamento de Datos Personales del{" "}
                  <strong>“Responsable”</strong>, mismo que usted puede
                  contactar como se indica en el punto 7 del presente Aviso de
                  Privacidad.
                </p>
                <span className="enfasisWP3 mt-2">
                  9.- Procedimiento para que el titular (Cliente), en su caso,
                  revoque su consentimiento para el tratamiento de sus datos
                  personales:
                </span>
                <p className="text-justify mt-2">
                  En cualquier momento, usted puede revocar el consentimiento
                  que, en su caso, haya otorgado al{" "}
                  <strong>“Responsable”</strong> para el tratamiento de sus
                  datos personales. Sin embargo, es importante que tenga en
                  cuenta que no en todos los casos se podrá atender su solicitud
                  o concluir el uso de forma inmediata, ya que, es posible que
                  por alguna obligación legal se requiera seguir tratando sus
                  datos personales. Asimismo, usted deberá considerar que para
                  ciertos fines, la revocación de su consentimiento implicará
                  que no se le pueda seguir prestando el servicio que solicitó,
                  o la conclusión de la relación con el{" "}
                  <strong>“Responsable”</strong>.
                </p>
                <p className="text-justify mt-2">
                  Para revocar su consentimiento es necesario que contacte al
                  Departamento de Protección de Datos Personales del{" "}
                  <strong>“Responsable”</strong>, a fin de conocer el
                  procedimiento para la revocación del consentimiento.
                </p>
                <span className="enfasisWP3 mt-2">
                  10.- Transferencias de datos personales dentro de México y al
                  extranjero:
                </span>
                <p className="text-justify mt-2">
                  Sus datos personales pueden ser transferidos y tratados dentro
                  y fuera del país, si fuere necesario para las finalidades
                  mencionadas en los incisos A) y B) del punto 3 del presente
                  Aviso de Privacidad. En este caso, se compartirían sus datos
                  personales fuera del país, con la o las sociedades
                  pertenecientes a{" "}
                  <strong>
                    Fraserga Franquicias y Servicios Gastronómicos, S. de R.L.
                    de C.V.
                  </strong>{" "}
                  Por lo anterior, es importante que usted esté enterado de que
                  sus datos se encuentran protegidos en virtud de que dichas
                  sociedades de ser necesario, que se remitan sus datos,
                  operarán bajo la misma Política de Protección de Datos
                  Personales y cuentan además con una Política de Protección de
                  Datos y Privacidad.
                </p>
                <span className="enfasisWP3 mt-2">
                  11.- Medidas de seguridad implementadas:
                </span>
                <p className="text-justify mt-2">
                  Para la protección de sus datos personales hemos instrumentado
                  medidas de seguridad de carácter administrativo, físico y
                  técnico con el objeto de evitar pérdidas, mal uso o alteración
                  de su información.
                </p>
                <span className="enfasisWP3 mt-2">
                  12.- Modificaciones al Aviso de Privacidad:
                </span>
                <p className="text-justify mt-2">
                  El presente Aviso de Privacidad puede sufrir modificaciones,
                  cambios o actualizaciones derivadas de nuevos requerimientos
                  legales, de las propias necesidades del{" "}
                  <strong>“Responsable”</strong> por los servicios que ofrece,
                  de las prácticas de privacidad o por otras causas, por lo que
                  el <strong>“Responsable”</strong> se reserva el derecho de
                  efectuar en cualquier momento las modificaciones o
                  actualizaciones que sean necesarias al presente Aviso de
                  Privacidad. El <strong>“Responsable”</strong> pondrá a
                  disposición del cliente, la versión actualizada del Aviso de
                  Privacidad, en la página de Internet que a continuación se
                  indica:{" "}
                  <a href="https://www.wingsplace.com.mx">
                    www.wingsplace.com.mx
                  </a>
                </p>
                <span className="enfasisWP3 mt-2">
                  13.- Derecho de promover los procedimientos de protección de
                  derechos y de verificación que sustancia el Instituto:
                </span>
                <p className="text-justify mt-2">
                  Cualquier queja o información adicional respecto de sus datos
                  personales o duda en relación con la Ley Federal de Protección
                  de Datos Personales en Posesión de los particulares o con su
                  Reglamento, podrá dirigirla al Instituto Nacional de
                  Transparencia, Acceso a la Información y Protección de Dato
                  Personales (INAI).
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AvisoPrivacidad;
