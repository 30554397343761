import React from "react";

const Menu_wp = () => {
  const numero_img = 22;
  const imageElements = [];
  const prefix_img = "021023MWP-";

  for (let i=1; i <= numero_img; i++) {
    const img = `./assets/img/menu_wp/${prefix_img+(i<=9?'0'+i:i)}.png`;
    const alt = `${prefix_img+(i<=9?'0'+i:i)}`;
    imageElements.push(
      <img 
      key={i} 
      src={img} 
      className="img_menu_wp" 
      alt={alt}/>
    );
  }
  return (
    <div className="container margin_navBar mb-4">
      <div className="row">
        <div className="col-12 text-center">
          {
            imageElements
          }
        </div>
      </div>
    </div>
  );
};

export default Menu_wp;
