import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";

const CarouselGallery = (props) => {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const getImages = async () => {
      let res = await fetch("helpers/galleryWp.json"),
        json = await res.json();
      json.ELEMENS.forEach(async (el, i) => {
        if (props.cvs - 1 === i) {
          el.IMAGES.forEach(async (el, i) => {
            let img = {
              NAME: "./assets/img/gallery/" + el.NAME,
              TITLE: el.TITLE,
              SUBTITLE: el.SUBTITLE,
            };
            setImages((images) => [...images, img]);
          });
        }
      });
    };
    getImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Carousel
        fade
        activeIndex={index}
        onSelect={handleSelect}
        className="CarouselGallery"
      >
        {images.length === 0 ? (
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./assets/img/promos_carousel/slide1.png"
              alt="DEFAULT"
            />
          </Carousel.Item>
        ) : (
          images.map((el, key) => (
            <Carousel.Item key={key} interval={2000}>
              <img className="d-block w-100" src={el.NAME} alt="Promo" />
              <Carousel.Caption>
                <h3>{el.TITLE}</h3>
                <p>{el.SUBTITLE}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ))
        )}
      </Carousel>
    </>
  );
};
export default CarouselGallery;
