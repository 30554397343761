/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { Card, Tab, Tabs, CardDeck } from "react-bootstrap";
import Load from "../assets/img/load_wp.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faPhone,
  faAt,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/img/Logo.svg";
const Sucursales = () => {
  const [sucursales, setSucursales] = useState([]);
  useEffect(() => {
    const getItemsMenu = async () => {
      let res = await fetch("helpers/sucursales.json"),
        json = await res.json();
      //console.log("JSONSUC",json);
      json.SUCURSALES.forEach((el, i) => {
        //console.log("NOMBRE", el.NOMBRE);
        let sucursal = {
          CVE: el.CVE,
          IMG: "./assets/img/" + el.IMG,
          NOMBRE: el.NOMBRE,
          DOMICILIO: el.DOMICILIO,
          TELEFONOS: el.TELEFONOS,
          EMAILS: el.EMAILS,
          HORARIOS: el.HORARIOS,
          MAPS: el.MAPS,
          APPS: el.APPS,
        };
        setSucursales((sucursales) => [...sucursales, sucursal]);
      });
      console.log("ELEMENS", sucursales);
    };
    getItemsMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="container margin_navBar">
        <div className="row">
          <div className="col-12">
            <h1 className="titulos-page mt-4">SUCURSALES</h1>
          </div>
        </div>
        <div className="row mt-2 mb-5">
          <div className="col-8 offset-2 text-center">
            {sucursales.length === 0 ? (
              <div className="text-center container-load">
                <img className="load_wp" alt="Cargando" src={Load} />
                <h1 className="title-load_wp">Cargando...</h1>
              </div>
            ) : (
              sucursales.map((el, key) => (
                <CardDeck key={el.CVE}>
                  <Card className="card-menu">
                    <Card.Header className="header-card">
                      <h1 className="enfasisWP">{el.NOMBRE}</h1>
                    </Card.Header>
                    <Card.Img variant="top" src={el.IMG} />
                    <Card.Body>
                      <Tabs className="tab-wp" defaultActiveKey="direccion">
                        <Tab eventKey="direccion" title="DIRECCIÓN">
                          <div className="container mt-4">
                            <div className="row">
                              <div className="col-12 col-md-6 text-left">
                                <p className="content-tab1">
                                  <FontAwesomeIcon
                                    icon={faMapMarkedAlt}
                                    className="icon_tab"
                                  />
                                  {el.DOMICILIO.CALLE}, #{el.DOMICILIO.NUMERO},{" "}
                                  {el.DOMICILIO.COLONIA},{" "}
                                  {el.DOMICILIO.DELEGACION}{el.DOMICILIO.CP},{" "}
                                  {el.DOMICILIO.CIUDAD}
                                </p>
                              </div>
                              <div className="col-12 col-md-6 text-center">
                                <object
                                  className="img-fluid load_wp_card"
                                  type="image/svg+xml"
                                  data={logo}
                                />
                                <iframe
                                  className="iframeMaps"
                                  src={el.MAPS}
                                  title="MAPS"
                                ></iframe>
                              </div>
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="horarios" title="HORARIOS">
                          <div className="container mt-4">
                            <div className="row">
                              <div className="col-12 text-left">
                                <table className="tab_horarios">
                                  <tbody>
                                    {el.HORARIOS.map((el, key) => (
                                      <tr key={key}>
                                        <td>{el.DIA}</td>
                                        <td>
                                          {el.OPEN} a {el.CLOSE}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="contacto" title="CONTACTO">
                          <div className="container content-contact mt-4">
                            <div className="row">
                              <div className="col-12 col-md-4 text-center">
                                <span className="text-tel">
                                  <FontAwesomeIcon
                                    icon={faPhone}
                                    className="icon_tab"
                                  />
                                  TEL(S):{" "}
                                </span>
                              </div>
                              <div className="col-12 col-md-8 text-left">
                                {el.TELEFONOS.map((el, key) => (
                                  <label key={key} className="text-tel2">
                                    {el}
                                  </label>
                                ))}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-4 text-center">
                                <span className="text-tel">
                                  <FontAwesomeIcon
                                    icon={faAt}
                                    className="icon_tab"
                                  />
                                  EMAIL(S):{" "}
                                </span>
                              </div>
                              <div className="col-12 col-md-8 text-left">
                                {el.EMAILS.map((el, key) => (
                                  <label key={key} className="text-tel2">
                                    {el}
                                  </label>
                                ))}
                              </div>
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="apps" title="APPS">
                          <div className="row mt-4">
                            <div className="col-12">
                              {el.APPS.map((el, key) => (
                                <a
                                key={el.key}
                                  href={el.URL}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <img className="icon-apps" src={el.ICON} alt={el.NAME} />
                                </a>
                              ))}
                            </div>
                          </div>
                        </Tab>
                      </Tabs>
                    </Card.Body>
                  </Card>
                </CardDeck>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Sucursales;
