import React from "react";
import "../assets/css/polaroid.css";

const QuienesSomos = () => {
  return (
    <>
      <div className="container margin_navBar">
        <div className="row">
          <div className="col-12">
            <h1 className="titulos-page mt-4">QUIENES SOMOS</h1>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-10 offset-1 fondo-parrafo1">
            <div className="content-parrafo1">
              <p className="parrafo-info">
                <span className="enfasisWP">Misión</span>
                <br />
                Ofrecer a nuestros Wingsloverslas mejores alitas en sabor y
                calidad a un precio justo.
                <br />
                <br />
                <span className="enfasisWP">Visión</span>
                <br />
                Ser reconocida como la empresa mexicana líder en la preparación
                de alitas, distinguida por su pasión y calidez en la atención al
                cliente.
              </p>
              <div className="parrafo-info">
                <span className="enfasisWP">Valores</span>
                <br />
                <ul>
                  <li>Originalidad</li>
                  <li>Pasión</li>
                  <li>Lealtad</li>
                  <li>Honestidad</li>
                  <li>Responsabilidad</li>
                  <li>Respeto</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default QuienesSomos;
