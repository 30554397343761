/* eslint-disable jsx-a11y/alt-text */
import CarouselBanner from "./CarouselBanner";
import CarouselGallery from "./CarouselGallery";
import { Link } from "react-router-dom";
export default function Home(props) {
  return (
    <>
      <div className="row-fluid margin_navBar">
        <CarouselBanner cvs={props.cvs} />
      </div>
      <div className="container contentHome">
        <div className="row mt-4 mb-4">
        <Link to="/pedidos"><img className="img-fluid" src="./assets/img/gallery/Servicio-a-Domicilio.gif" alt="ENVIOS"/></Link>
        </div>
        <div className="row mt-4 mb-4">
          <div className="col-12 col-md-6 text-center">
            <h1 className="titulos">Picometro</h1>
            <div className="">
              <img className="img-fluid" src="./assets/img/02102023_picometer.png" alt="picometro_wp" />
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <h1 className="titulos">Galeria</h1>
            <div className="content-gallery row">
            <CarouselGallery cvs={props.cvs}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
