import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

import { Navbar, Nav, NavDropdown, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
let handleCollapse = () => {
  //console.log("handleCollapse");
  let btn = document.getElementsByClassName("navbar-toggler")[0];
  //console.log(btn.innerHTML);
  btn.click();
};
function isMobile(){
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let movil = (
    /android/i.test(userAgent) ||
    (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
  );
  console.log("ES MOVIL",movil)
  return movil;
};
export default function NabBarWP() {
  return (
    <div className="">
      <Navbar
        id="navbarNav"
        bg="light"
        expand="lg"
        fixed="top"
        className="nav_shadow"
      >
        <Navbar.Brand>
          <img
            src="logo.svg"
            width="100"
            height="50"
            className="d-inline-block align-top"
            alt="Logo Wings PLace"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavLink
              to="/"
              onClick={handleCollapse}
              className="nav-link"
              activeClassName="active"
            >
              Home
            </NavLink>
            {isMobile()?(
              <NavLink className="nav-link" onClick={handleCollapse} to="/menu">
              MENÚ
            </NavLink>
           /*  <NavLink className="nav-link" onClick={handleCollapse} to={{ pathname:"https://wingsplace.com.mx/menu_wp/menu_2022.pdf"}} target="_blank">
              MENÚ
            </NavLink> */
            ):(
              <NavLink className="nav-link" onClick={handleCollapse} to="/menu">
              MENÚ
            </NavLink>
            /* <NavDropdown title="Menú" id="basic-nav-dropdown">
              <NavLink
                className="dropdown-item"
                onClick={handleCollapse}
                to="/alitas_boneless"
              >
                ALITAS Y BONELESS
              </NavLink>
              <NavLink
                className="dropdown-item"
                onClick={handleCollapse}
                to="/paquetes"
              >
                PAQUETES
              </NavLink>
              <NavLink
                className="dropdown-item"
                onClick={handleCollapse}
                to="/complementos"
              >
                COMPLEMENTOS
              </NavLink>
              <NavLink
                className="dropdown-item"
                onClick={handleCollapse}
                to="/h_hd"
              >
                HAMBURGUESAS Y HOT DOGS
              </NavLink>
              <NavDropdown.Divider />
              <NavLink
                className="dropdown-item"
                onClick={handleCollapse}
                to="/bebidas"
              >
                BEBIDAS NATURALES
              </NavLink>
              <NavLink
                className="dropdown-item"
                onClick={handleCollapse}
                to="/cervezas"
              >
                CERVEZAS
              </NavLink>
              <NavLink
                className="dropdown-item"
                onClick={handleCollapse}
                to="/cocteles"
              >
                COCTELES
              </NavLink>
              <NavDropdown.Divider style={{ display: "none" }} />
              <NavLink
                style={{ display: "none" }}
                className="dropdown-item"
                to="/postres"
              >
                POSTRES
              </NavLink>
            </NavDropdown> */
            )}
            <NavLink
              className="nav-link"
              onClick={handleCollapse}
              to="/promociones"
            >
              Promociones
            </NavLink>
            <NavLink
              className="nav-link"
              onClick={handleCollapse}
              to="/sucursales"
            >
              Sucursales
            </NavLink>
          </Nav>
          <Form inline className="text-center">
            <div className="mx-auto">
              <a
                href="https://www.facebook.com/wingsplace.mexico/"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon
                  className="d-inline-block align-top mr-2 icon_nav"
                  icon={faFacebook}
                />
              </a>
              <a
                href="https://www.instagram.com/wingsplacemx/?hl=es-la"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon
                  className="d-inline-block align-top mr-2 icon_nav"
                  icon={faInstagram}
                />
              </a>
            </div>
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
