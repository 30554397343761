import React, { useState, useEffect } from "react";
import { Card, Badge, CardColumns } from "react-bootstrap";
import Load from "../assets/img/load_wp.gif";
const Paquetes = (props) => {
    
  const [menu, setMenu] = useState([]);
  useEffect(() => {
    const getItemsMenu = async () => {
      let res = await fetch("helpers/menu.json"),
        json = await res.json();
      json.ELEMENS.forEach((el, i) => {
        if (props.cvs === el.FK_SUCURSAL) {
          let elements = [];
          el.LISTA.PAQUETES.forEach((el, i) => {
            let menu_item = {
              KEY: el.KEY,
              IMG: "./assets/img/menu/paquetes/" + el.IMG,
              TITLE: el.TITLE,
              SUBTITLE: el.SUBTITLE,
              PRICE: el.PRICE,
            };
            elements.push(menu_item);
            if (elements.length === 3) {
              setMenu((menu) => [...menu, elements]);
              elements = [];
            }
          });
          if(elements.length>0){
            setMenu((menu) => [...menu, elements]);
            elements = [];
          }
        }
      });
    //   console.log("ELEMENS", menu);
    };
    getItemsMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="container margin_navBar">
        <div className="row">
          <div className="col-12">
            <h1 className="titulos-page mt-4">PAQUETES</h1>
          </div>
        </div>
        <div className="row mt-2 mb-5">
          <div className="col-12 text-center">
            {menu.length === 0 ? (
              <div className="text-center container-load">
              <img className="load_wp" alt="Cargando" src={Load}/>
              <h1 className="title-load_wp">Cargando...</h1>
              </div>
            ) : (
              menu.map((el, key) => (
                <CardColumns key={key}>
                  {el.map((el, key) => (
                    <Card key={el.KEY} className="card-menu">
                      <Card.Img variant="top" src={el.IMG} />
                      <Card.Body>
                        <Card.Title className="titleMenu">
                          {el.TITLE}
                        </Card.Title>
                        <Card.Text>{el.SUBTITLE}</Card.Text>
                        <table className="tableExtras">
                          <tbody>
                            <tr>
                              <td>Salsa extra</td>
                              <td className="price-tab">$ 15.00</td>
                            </tr>
                            <tr>
                              <td>Aderezo extra</td>
                              <td className="price-tab">$ 12.00</td>
                            </tr>
                            <tr>
                              <td>Vegetales extra </td>
                              <td className="price-tab">$ 12.00</td>
                            </tr>
                          </tbody>
                        </table>
                        <h3>
                          <Badge variant="danger">{el.PRICE}</Badge>
                        </h3>
                      </Card.Body>
                    </Card>
                  ))}
                </CardColumns>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Paquetes;
