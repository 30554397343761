// eslint-disable-next-line
import "./App.css";
import React, { useState, useEffect } from "react";
import NabBarWP from "./components/NabBarWP";
import Home from "./components/Home";
import Promociones from "./components/Promociones";
import Sucursales from "./components/Sucursales";
import Historia from "./components/Historia";
import QuienesSomos from "./components/QuienesSomos";
import FooterWP from "./components/FooterWP";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AlitasBoneless from "./components/AlitasBoneless";
import Paquetes from "./components/Paquetes";
import Complementos from "./components/Complementos";
import Hhd from "./components/Hhd";
import Bebidas from "./components/Bebidas";
import Cervezas from "./components/Cervezas";
import Cocteles from "./components/Cocteles";
import Contacto from "./components/Contacto";
import LoadPage from "./components/LoadPage";
// import Menu from "./components/Menu";
import MenuWP from "./components/Menu_wp";
import ModalPrincipal from "./components/ModalPrincipal";
import PedidosDomicilio from "./components/PedidosDomicilio";
import AvisoPrivacidad from "./components/AvisoPrivacidad";

function App() {
  let clave_sucursal = 1;
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let stateCheck = setInterval(() => {
      if (document.readyState === "complete") {
        setLoading(false);
        clearInterval(stateCheck);
      }
    }, 100);
    //setTimeout(() => setLoading(false), 6000)
  }, []);

  return (
    <>
      {loading === false ? (
        <BrowserRouter>
          <div className="content">
            <NabBarWP />
            <Switch>
              <Route path="/pedidos" component={PedidosDomicilio} />
              <Route path="/promociones">
                <Promociones cvs={clave_sucursal} />
              </Route>
              <Route path="/sucursales" component={Sucursales} />
              <Route path="/historia">
                <Historia />
              </Route>
              <Route path="/quienes_somos">
                <QuienesSomos />
              </Route>
              <Route path="/aviso_privacidad">
                <AvisoPrivacidad/>
              </Route>
              <Route path="/contacto">
                <Contacto />
              </Route>
              <Route path="/alitas_boneless">
                <AlitasBoneless cvs={clave_sucursal} />
              </Route>
              <Route path="/paquetes">
                <Paquetes cvs={clave_sucursal} />
              </Route>
              <Route path="/complementos">
                <Complementos cvs={clave_sucursal} />
              </Route>
              <Route path="/h_hd">
                <Hhd cvs={clave_sucursal} />
              </Route>
              <Route path="/bebidas">
                <Bebidas cvs={clave_sucursal} />
              </Route>
              <Route path="/cervezas">
                <Cervezas cvs={clave_sucursal} />
              </Route>
              <Route path="/cocteles">
                <Cocteles cvs={clave_sucursal} />
              </Route>
              <Route path="/menu">
                <MenuWP />
                {/* <Menu cvs={clave_sucursal} /> */}
              </Route>
              <Route path="/" exact>
                <Home cvs={clave_sucursal} />
                <ModalPrincipal
                  show={visible}
                  onHide={() => setVisible(false)}
                />
              </Route>
            </Switch>
            <FooterWP />
            <MessengerCustomerChat
              pageId="1781965312042121"
              appId="529260114782520"
            />
          </div>
        </BrowserRouter>
      ) : (
        <LoadPage />
      )}
    </>
  );
}

export default App;
