import React, { useState, useEffect } from "react";
import Load from "../assets/img/load_wp.gif";

const Promociones = (props) => {
  const [promos, setPromos] = useState([]);
  useEffect(() => {
    const getItemsMenu = async () => {
      let res = await fetch("helpers/promociones.json"),
        json = await res.json();
      //console.log("JSONSUC",json);
      json.SUCURSALES.forEach((el, i) => {
        if (props.cvs === el.CVE) {
          el.PROMOCIONES.forEach((el, i) => {
            //console.log("NOMBRE", el.KEY);
            let promo = {
              KEY: el.KEY,
              IMG: "./assets/img/promos/" + el.IMG,
              NAME: el.NAME,
              TYC: el.TYC,
            };
            setPromos((promos) => [...promos, promo]);
          });
        }
      });
      //console.log("ELEMENS", promos);
    };
    getItemsMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
     {/*  <div className="container margin_navBar">
        <div className="row">
          <div className="col-12">
            <h1 className="titulos-page mt-4 mb-p">Próximamente</h1>
          </div>
        </div>
      </div> */}

      <div className="container margin_navBar">
        <div className="row">
          <div className="col-12">
            <h1 className="titulos-page mt-4">PROMOCIONES</h1>
          </div>
        </div>
        <div className="row mt-4 mb-5">
          <div className="col-10 offset-1">
            {promos.length === 0 ? (
              <div className="text-center container-load">
                <img className="load_wp" alt="Cargando" src={Load} />
                <h1 className="title-load_wp">Cargando...</h1>
              </div>
            ) : (
              promos.map((el, key) => (
                <div className="row  mt-4" key={el.KEY}>
                  <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                    <img className="img-fluid" alt="OldWP" src={el.IMG} />
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="content-parrafo2">
                      <div className="marco-terminos">
                        <h1 className="enfasisWP2">{el.NAME.toUpperCase()}</h1>
                        <div className="content-promo1">
                          <ul>
                            {el.TYC.map((el, key) => (
                              <li key={key}>{el.toUpperCase()}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Promociones;
