import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
const PedidosDomicilio = () => {
  return (
    <div className="container contentHome mb-4">
      <div className="row mb-4 bannerdomicilio">
        <img
          className="img-fluid"
          src="./assets/img/gallery/Servicio-a-Domicilio.gif"
          alt="ENVIOS"
        />
      </div>
      <div className="row-fluid">
        <h3 className="text-center text-white">Pedido directo en sucursal</h3>
        <h4 className="text-justify">
          <span className="text-white-50">Para hacer tu pedido comunícate con nosotros vía telefónica y con
          gusto tomamos tu orden </span> <span className="text-danger">(Distancia máxima 4km)</span>
        </h4>
      </div>
      <div className="row-fluid">
        <h2 className="text-center enfasisWP3 text-white">San Andrés Tomatlán</h2>
        <div className="text-center">
          <h4 className="text-white">(55) 2790-9685 </h4>
          <a href="tel:+525527909685" className="btn btn-domicilio btn-block">
            <FontAwesomeIcon icon={faPhoneSquare} /> Llamar
          </a>
        </div>
      </div>

      {/* <div className="row-fluid mt-4">
        <h2 className="text-center enfasisWP3 text-white">Zapotitlán</h2>
        <div className="text-center">
          <h4 className="text-white">(55) 3128-3124</h4>
          <a href="tel:+525531283124" className="btn btn-domicilio btn-block">
            <FontAwesomeIcon icon={faPhoneSquare} /> Llamar
          </a>
          <h4 className="text-white">(55) 8961-7985</h4>
          <a href="tel:+525589617985" className="btn btn-domicilio btn-block">
            <FontAwesomeIcon icon={faPhoneSquare} /> Llamar
          </a>
        </div>
      </div> */}

      <div className="row-fluid mt-4">
        <h2 className="text-center enfasisWP3 text-white">Tláhuac</h2>
        <div className="text-center">
          <h4 className="text-white">(55) 5123-3866</h4>
          <a href="tel:+525551233866" className="btn btn-domicilio btn-block">
            <FontAwesomeIcon icon={faPhoneSquare} /> Llamar
          </a>
        </div>
      </div>
      <div className="row-fluid mt-4">
        <h2 className="text-center enfasisWP3 text-white">Escuadrón 201</h2>
        <div className="text-center">
          <h4 className="text-white">(55) 9245-1722</h4>
          <a href="tel:+525592451722" className="btn btn-domicilio btn-block">
            <FontAwesomeIcon icon={faPhoneSquare} /> Llamar
          </a>
        </div>
      </div>

      {/* <div className="row-fluid mt-4">
        <h2 className="text-center enfasisWP3 text-white">Tecómitl</h2>
        <div className="text-center">
          <h4 className="text-white">(55) 9222-8751</h4>
          <a href="tel:+525592228751" className="btn btn-domicilio btn-block">
            <FontAwesomeIcon icon={faPhoneSquare} /> Llamar
          </a>
        </div>
      </div> */}

      {/* <div className="row-fluid mt-4">
        <h2 className="text-center enfasisWP3 text-white">El Imán</h2>
        <div className="text-center">
          <h4 className="text-white">(55) 9102-5249</h4>
          <a href="tel:+525591025249" className="btn btn-domicilio btn-block">
            <FontAwesomeIcon icon={faPhoneSquare} /> Llamar
          </a>
        </div>
      </div> */}
      <div className="row-fluid mt-4">
        <h2 className="text-center enfasisWP3 text-white">Del Valle</h2>
        <div className="text-center">
          <h4 className="text-white">(55) 5548-9475</h4>
          <a href="tel:+525555489475" className="btn btn-domicilio btn-block">
            <FontAwesomeIcon icon={faPhoneSquare} /> Llamar
          </a>
          <h4 className="text-white">(55) 9277-1114</h4>
          <a href="tel:+525592771114" className="btn btn-domicilio btn-block">
            <FontAwesomeIcon icon={faPhoneSquare} /> Llamar
          </a>
        </div>
      </div>
    </div>
  );
};
export default PedidosDomicilio;
