import React from 'react'

const LoadPage = () => {
    return (
        <div className="splash-screen text-center">
            <img className="loadPage" alt="lodingApp" src="./assets/img/load_wp.gif"/>
            {/* <h1 className="enfasisWP2 title-Load">Bienvenido espere un moento...</h1> */}
        </div>
    )
}

export default LoadPage;
